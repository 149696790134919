<template>
  <g>
    <svg:style>
      .cls-3,
      .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="BG-gradient"
      :x1="doorLeftWidth1 + 70.82"
      :y1="doorTopHeight1 + 0.12"
      :x2="doorLeftWidth1 + 70.82"
      :y2="doorTopHeight1 + 297.04"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.25" stop-color="#f9f9f9" />
      <stop offset="0.67" stop-color="#e8e8e8" />
      <stop offset="0.79" stop-color="#e2e2e2" />
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 51"
      :y1="doorTopHeight1 + 243"
      :x2="doorLeftWidth1 + 51"
      :y2="doorTopHeight1 + 51"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.01" stop-color="#babbbc" />
      <stop offset="0.29" stop-color="#989a9e" />
      <stop offset="0.65" stop-color="#bdbdc1" />
      <stop offset="0.87" stop-color="#9d9fa2" />
      <stop offset="1" stop-color="#a6a8aa" />
      <stop offset="1" stop-color="#c2c5c9" />
    </linearGradient>
    <linearGradient
      id="glass-gradient"
      x1="53.14"
      y1="240.9"
      x2="53.14"
      y2="59.92"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f4f4f4" />
      <stop offset="0.5" stop-color="#dee8e8" />
      <stop offset="1" stop-color="#d1cece" />
    </linearGradient>
    <linearGradient
      id="handle-gradient"
      x1="11.29"
      y1="152.41"
      x2="16.95"
      y2="152.41"
      xlink:href="#inox-gradient"
    />
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0" />
      <feGaussianBlur stdDeviation="1" result="offset-blur" />
      <feComposite
        operator="out"
        in="SourceGraphic"
        in2="offset-blur"
        result="inverse"
      />
      <feFlood flood-color="black" flood-opacity="1" result="color" />
      <feComposite operator="in" in="color" in2="inverse" result="shadow" />
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25" />
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic" />
    </filter>
    <g id="V02">
      <!--       <polyline
        id="inox"
        v-if="showInox"
        data-name="inox"
        class="cls-3"
        :points="
          `${doorLeftWidth1 + 70.81} ${doorTopHeight1 +
            245.84} ${doorLeftWidth1 + 70.81} ${doorTopHeight1 +
            54.98} ${doorLeftWidth1 + 35.47} ${doorTopHeight1 +
            54.98} ${doorLeftWidth1 + 35.47} ${doorTopHeight1 +
            245.84} ${doorLeftWidth1 + 70.81} ${doorTopHeight1 + 245.84}`
        "
      />
      <polyline
        id="glass"
        class="cls-4"
        :points="
          `${doorLeftWidth1 + 40.41} ${doorTopHeight1 +
            240.9} ${doorLeftWidth1 + 65.86} ${doorTopHeight1 +
            240.9} ${doorLeftWidth1 + 65.86} ${doorTopHeight1 +
            59.92} ${doorLeftWidth1 + 40.41} ${doorTopHeight1 +
            59.92} ${doorLeftWidth1 + 40.41} ${doorTopHeight1 + 240.9}`
        "
      /> -->
      <rect
        id="inox"
        v-if="showInox"
        data-name="inox"
        :x="doorLeftWidth + 32"
        :y="doorTopHeight + 51"
        width="37"
        height="189"
        class="cls-3"
      ></rect>
      <rect
        filter="url(#inset-shadow)"
        id="glass"
        class="cls-4"
        :x="doorLeftWidth + 38.11"
        :y="doorTopHeight1 + 59.18"
        width="24.72"
        height="178.74"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox'
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth / 2 - 71;
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight / 2 - 153;
    }
  }
};
</script>
